
<template>
  <div id="box">
    <h1>焦虑量表问卷</h1>
    <p>
      请仔细阅读每一道题，根据自身最近一周内（包括当天）被各种症状烦扰的程度进行选择。
    </p>
    <div>
      <h4>1.身体麻木或刺痛</h4>
      <el-radio-group v-model="radio">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
        <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
        <el-radio label="3">D: 重度，只能勉强忍受</el-radio
        ><br /> </el-radio-group
      ><br />
      <h4>2.感到发热</h4>
      <el-radio-group v-model="radio1">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
        <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
        <el-radio label="3">D: 重度，只能勉强忍受</el-radio
        ><br /></el-radio-group
      ><br />
      <br />
      <h4>3.腿部颤抖</h4>
      <el-radio-group v-model="radio2">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
        <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
        <el-radio label="3">D: 重度，只能勉强忍受</el-radio
        ><br /></el-radio-group
      ><br />
      <br />
      <h4>4.不能放松</h4>
      <el-radio-group v-model="radio3">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
        <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
        <el-radio label="3">D: 重度，只能勉强忍受</el-radio
        ><br /></el-radio-group
      ><br />
      <br />
    </div>
    <br />
    <h4>5.害怕要发生不好的事情</h4>
    <el-radio-group v-model="radio4">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>6.感到头晕目眩</h4>
    <el-radio-group v-model="radio5">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>7.心悸或心率加快</h4>
    <el-radio-group v-model="radio6">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>8.心神不宁</h4>
    <el-radio-group v-model="radio7">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>9.感到惊吓</h4>
    <el-radio-group v-model="radio8">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>10.感到惊吓</h4>
    <el-radio-group v-model="radio9">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>11.有窒息感</h4>
    <el-radio-group v-model="radio10">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>12.手发抖</h4>
    <el-radio-group v-model="radio11">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>13.摇晃</h4>
    <el-radio-group v-model="radio12">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>14.害怕失控</h4>
    <el-radio-group v-model="radio13">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>15.呼吸困难</h4>
    <el-radio-group v-model="radio14">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>16.害怕快要死去</h4>
    <el-radio-group v-model="radio15">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>17.感到恐慌</h4>
    <el-radio-group v-model="radio16">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>18.消化不良或腹部不适</h4>
    <el-radio-group v-model="radio17">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>19.昏厥</h4>
    <el-radio-group v-model="radio18">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>20.脸发红</h4>
    <el-radio-group v-model="radio19">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <h4>21.非天气炎热而出汗</h4>
    <el-radio-group v-model="radio20">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻度，无多大烦扰 </el-radio><br />
      <el-radio label="2">C：中度，感到不适但尚能忍受 </el-radio><br />
      <el-radio label="3">D: 重度，只能勉强忍受</el-radio><br /></el-radio-group
    ><br />
    <el-button type="success" @click="ExamSolutionADD" :disabled="isdisabled"
      >提交</el-button
    >
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
  mounted() {
    this.get();
  },
  updated() {
    // console.log("总分：", this.total);
    // console.log("说明", this.explain);
  },
  methods: {
    async ExamSolutionADD() {
      const data = await axios({
        url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=焦虑量表评估&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
        method: "post",
        data,
      });
      if (data.data.data == true) {
        this.isShow = !this.isShow;
        // 存储BAI的分数到本地存储
        const BAIScore = this.total;
        localStorage.setItem("BAI", JSON.stringify(BAIScore));
        Message.success("提交成功，进入下一个量表评估");
        this.$router.push("/PSQI");
      }
    },
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    get() {
      const patientN = localStorage.getItem("patientN");
      const patientI = localStorage.getItem("patientI");
      this.name = JSON.parse(patientN);
      this.cid = JSON.parse(patientI);
    },
    //  // 点击提交上传评分，并且删除本地存储的患者信息
    // test() {
    //   console.log(11111111, this.name);
    //   localStorage.removeItem("patientN");
    //   localStorage.removeItem("patientI");
    // },
  },
  computed: {
    isdisabled() {
      if (
        this.radio == "" ||
        this.radio1 == "" ||
        this.radio2 == "" ||
        this.radio3 == "" ||
        this.radio4 == "" ||
        this.radio5 == "" ||
        this.radio6 == "" ||
        this.radio7 == "" ||
        this.radio8 == "" ||
        this.radio9 == "" ||
        this.radio10 == "" ||
        this.radio11 == "" ||
        this.radio12 == "" ||
        this.radio13 == "" ||
        this.radio14 == "" ||
        this.radio15 == "" ||
        this.radio16 == "" ||
        this.radio17 == "" ||
        this.radio18 == "" ||
        this.radio19 == "" ||
        this.radio20 == ""
      ) {
        return true;
      }
      if (
        this.radio != "" ||
        this.radio1 != "" ||
        this.radio2 != "" ||
        this.radio3 != "" ||
        this.radio4 != "" ||
        this.radio5 != "" ||
        this.radio6 != "" ||
        this.radio7 != "" ||
        this.radio8 != "" ||
        this.radio9 != "" ||
        this.radio10 != "" ||
        this.radio11 != "" ||
        this.radio12 != "" ||
        this.radio13 != "" ||
        this.radio14 != "" ||
        this.radio15 != "" ||
        this.radio16 != "" ||
        this.radio17 != "" ||
        this.radio18 != "" ||
        this.radio19 != "" ||
        this.radio20 != ""
      ) {
        return false;
      }
    },
    explain() {
      if (this.total < 15) {
        return "无焦虑";
      }
      if (this.total >= 15 && this.total <= 25) {
        return "轻度焦虑";
      }
      if (this.total >= 26 && this.total <= 35) {
        return "中度焦虑";
      }
      if (this.total >= 36) {
        return "重度焦虑";
      }
    },
    total() {
      return (
        this.radio * 1 +
        this.radio1 * 1 +
        this.radio2 * 1 +
        this.radio3 * 1 +
        this.radio4 * 1 +
        this.radio5 * 1 +
        this.radio6 * 1 +
        this.radio7 * 1 +
        this.radio8 * 1 +
        this.radio9 * 1 +
        this.radio10 * 1 +
        this.radio11 * 1 +
        this.radio12 * 1 +
        this.radio13 * 1 +
        this.radio14 * 1 +
        this.radio15 * 1 +
        this.radio16 * 1 +
        this.radio17 * 1 +
        this.radio18 * 1 +
        this.radio19 * 1 +
        this.radio20 * 1
      );
    },
    // a1() {
    //   return this.radio * 1;
    // },
    // a2() {
    //   return this.radio3 * 1;
    // },
    // a3() {
    //   return this.radio8 * 1;
    // },
  },
  data() {
    return {
      isShow: false,
      ETID: "E7",
      name: "",
      cid: "",
      radio: "",
      radio1: "",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
      radio10: "",
      radio11: "",
      radio12: "",
      radio13: "",
      radio14: "",
      radio15: "",
      radio16: "",
      radio17: "",
      radio18: "",
      radio19: "",
      radio20: "",
    };
  },
};
</script>

<style lang='scss' scoped>
// @import "./style.scss";
.el-radio {
  margin: 10px;
}
body {
  background: rgb(220, 245, 179);
}
#box {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-left: 30px;
  // background: rgb(206, 245, 175);
}
.label_color {
  color: rgb(202, 44, 207);
}
h1 {
  text-align: center;
}

h4 {
  color: rgb(140, 102, 245);
}
</style>

